import { MessageClient } from 'javascript-wave-cde-utils/src/message_client';
import { SandboxedFrame } from 'javascript-wave-cde-utils/src/sandbox_frame';

import { ErrorTypes, DisplayTypes, FormModes, Orientations, Providers } from './constants';

class WPCheckout {
  constructor() {
    this.onClose = () => {};
    this.onSuccess = () => {};
    this.onError = () => {};
    this.onHeightChange = () => {};
    this.iframe = null;
    this.messageClient = null;
    this.provider = Providers.PAY_PAGE;

    // Expose constants for consumers (to avoid magic strings)
    this.MODES = { ...FormModes };
    this.DISPLAY_TYPES = { ...DisplayTypes };
    this.ORIENTATIONS = { ...Orientations };
    this.ERROR_TYPES = { ...ErrorTypes };
    this.PROVIDERS = { ...Providers };
  }

  configure({
    autoFocus,
    amount,
    key,
    closeOnSuccess,
    container,
    headerText,
    maxAmount,
    minAmount,
    onClose,
    onSuccess,
    onError,
    displayType,
    displayZipCode,
    mode,
    orientation,
    onHeightChange,
    showTokenizeError,
    provider,
    currencySymbol,
    currency,
    saveCreditCardLabel,
    saveCreditCardLabelTOSLink,
    requireSaveCreditCard,
    forceSavedByDefault,
    noBorderAroundForm,
    displaySubmitButton,
    isExperimentVariant,
    allowAmex,
    businessCountry,
    cardForInstantPayouts,
    stripFormPadding,
    termsOfServiceLabel,
    termsOfServiceUrl,
    requireTermsOfServiceAgreement,
    showDigitalWallets,
  }) {
    this.onClose = onClose || this.onClose;
    this.onSuccess = onSuccess || this.onSuccess;
    this.onError = onError || this.onError;
    this.onHeightChange = onHeightChange || this.onHeightChange;
    this.provider = provider || this.provider;

    if (key === undefined) {
      throw new Error('Missing required argument `key`');
    }
    if (amount === undefined) {
      throw new Error('Missing required argument `amount`');
    }
    const absoluteMinAmount = 51;
    if (minAmount !== undefined && minAmount < absoluteMinAmount) {
      throw new Error('`minAmount` cannot be under ' + absoluteMinAmount);
    }
    container = container || document.body;
    displayType = displayType || DisplayTypes.MODAL;
    headerText = headerText || null;
    maxAmount = maxAmount || null;
    minAmount = minAmount || absoluteMinAmount;
    mode = mode || FormModes.SALE;
    orientation = orientation || Orientations.PORTRAIT;
    showTokenizeError = showTokenizeError === undefined ? true : showTokenizeError;
    currencySymbol = currencySymbol || '$';
    currency = currency || 'USD';
    saveCreditCardLabel = saveCreditCardLabel || 'Save this card for future payments.';
    saveCreditCardLabelTOSLink = saveCreditCardLabelTOSLink || null;
    requireSaveCreditCard = requireSaveCreditCard ? true : false;
    forceSavedByDefault = forceSavedByDefault || false;
    noBorderAroundForm = noBorderAroundForm || false;
    autoFocus = autoFocus !== false;
    displaySubmitButton = displaySubmitButton === undefined ? true : displaySubmitButton;
    isExperimentVariant = isExperimentVariant === undefined ? false : isExperimentVariant;
    allowAmex = allowAmex === undefined ? true : allowAmex;
    businessCountry = businessCountry || undefined;
    cardForInstantPayouts = cardForInstantPayouts === undefined ? false : cardForInstantPayouts;
    stripFormPadding = stripFormPadding === undefined ? false : stripFormPadding;

    requireTermsOfServiceAgreement = requireTermsOfServiceAgreement || false;
    showDigitalWallets = showDigitalWallets || false;

    // this.closeOnSuccess should default to true if your displayType is MODAL, false for INLINE,
    // unless explicitly specified
    this.closeOnSuccess =
      closeOnSuccess === undefined ? displayType === DisplayTypes.MODAL : closeOnSuccess;

    const args = {
      autoFocus,
      amount,
      displayType,
      displayZipCode,
      headerText,
      host:
        `${window.location.protocol}//${window.location.hostname}` +
        (window.location.port ? `:${window.location.port}` : ''),
      key,
      maxAmount,
      minAmount,
      mode,
      orientation,
      provider,
      showTokenizeError,
      currencySymbol,
      currency,
      saveCreditCardLabel,
      saveCreditCardLabelTOSLink,
      requireSaveCreditCard,
      forceSavedByDefault,
      noBorderAroundForm,
      displaySubmitButton,
      isExperimentVariant,
      allowAmex,
      businessCountry,
      cardForInstantPayouts,
      stripFormPadding,
      termsOfServiceLabel,
      termsOfServiceUrl,
      requireTermsOfServiceAgreement,
      showDigitalWallets,
    };

    const formLocation = CHECKOUT_PATH + '/form.html';
    this.iframe = new SandboxedFrame({
      modal: displayType === 'modal',
      src: formLocation,
      args,
    });
    const frame = this.iframe.create();
    container.appendChild(frame);
    const iframeHost = this.iframe.hostWithProtocol;
    this.messageClient = new MessageClient({ target: frame.contentWindow, host: iframeHost });
    this.messageClient.receiveMessage('token', data => this._tokenSuccess(data));
    this.messageClient.receiveMessage('closed', () => this.close());
    this.messageClient.receiveMessage('onError', data => this.error(data.errorType));
    this.messageClient.receiveMessage('onHeightChange', data => this._onHeightChange(data));

    return this;
  }

  // Public methods
  open() {
    this.iframe.open();
  }

  close() {
    this.iframe.close();
    this.onClose();
  }

  error(errorType) {
    this.onError(errorType);
  }

  submitPayment() {
    this.messageClient.sendMessage({ name: 'submitPayment' });
  }

  setPaymentAmount(amount) {
    this.messageClient.sendMessage({ name: 'setPaymentAmount', amount });
  }

  paymentComplete() {
    this.messageClient.sendMessage({ name: 'paymentComplete' });
  }

  paymentEditing() {
    this.messageClient.sendMessage({ name: 'paymentEditing' });
  }

  setErrors(errors) {
    this.messageClient.sendMessage({ name: 'setErrors', errors });
  }

  // Internal
  _tokenSuccess(data) {
    console.log('got data: ', data);
    if (this.closeOnSuccess) {
      this.close();
    }
    this.onSuccess(data.data);
  }

  _onHeightChange(data) {
    this.onHeightChange(data.height);
  }
}

window.WPCheckout = WPCheckout;
export default WPCheckout;
